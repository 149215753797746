<template>
  <div class="container-fluid community">
    <div class="container pb-2">
      <h2 class="text-center pt-5 pb-4">Community Guidelines</h2>

      <p class="medium pb-3">
        Hi there! Healthy Hip Hop promotes a healthy lifestyle through technology
        and music. We also want to create a safe space for everyone in our H3
        ecosystem. Thus, we created a community guideline as a guideline for users
        on their content. This is to protect every user from disrespect,
        discrimination, and corruption.
      </p>

      <p class="medium pb-3"><b>Here are some things we really care about:</b></p>

      <h3>Security &amp; Safety</h3>
      <p class="medium pb-3">
        We make sure that our community is secured and cannot be corrupted in
        any aspect possible. We will hide reported information that will endanger
        our users’ safety and security when handled incorrectly. These types of
        information include but are not limited to financial information,
        location, and identity.
      </p>

      <h3>Language</h3>

      <h3>Actions</h3>
    </div>
  </div>
</template>

<script>
  export default {
    name : 'CommunityStandards',
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/components/manual/community-standards';
</style>